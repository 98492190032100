import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ShoppingCartIcon } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Link, Navigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Toastify from "toastify-js";
import "react-toastify/dist/ReactToastify.css";

const SavedOrders = () => {
  const [savedOrders, setSavedOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchSavedOrders(currentUser.uid);
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const addToCartHandler = (order) => {
    let cartItems = JSON.parse(localStorage.getItem("cart")) || [];

    order.products.forEach((product) => {
      // if (!product.product) {
      //   console.error("Product missing ID:", product);
      //   return; // Skip this product
      // }
      console.log(product.productId);

      const existingItem = cartItems.find(
        (item) => item.id === product.productId
      );

      if (existingItem) {
        existingItem.quantity += product.quantity;
      } else {
        cartItems.push({ id: product.productId, quantity: product.quantity });
      }
    });

    console.log("Updated cart items:", cartItems);
    localStorage.setItem("cart", JSON.stringify(cartItems));

    Toastify({
      text: `Added ${order.name} to Cart!`,
      duration: 1500,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "left",
      stopOnFocus: true,
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
  };
  const fetchSavedOrders = async (uid) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customers/${uid}/myorders`
      );
      setSavedOrders(response.data.savedOrders);
    } catch (err) {
      console.error("Error fetching saved orders:", err);
      setError("Failed to fetch saved orders. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (orderId) => {
    if (!user) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/customers/${user.uid}/myorders/${orderId}`
      );
      setSavedOrders(savedOrders.filter((order) => order._id !== orderId));
    } catch (err) {
      console.error("Error deleting saved order:", err);
      setError("Failed to delete saved order. Please try again.");
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const openDeleteDialog = (order) => {
    setOrderToDelete(order);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setOrderToDelete(null);
    setDeleteDialogOpen(false);
  };

  const confirmDelete = async () => {
    if (orderToDelete) {
      await handleDelete(orderToDelete._id);
      closeDeleteDialog();
    }
  };
  return (
    <section className="w-full py-12">
      <div className="container grid gap-8 px-4 md:px-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/cu/dashboard">
                <BreadcrumbLink>Dashboard</BreadcrumbLink>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Saved Orders</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-8">
          <div className="grid gap-1">
            <h1 className="text-2xl font-bold tracking-tight">Saved Orders</h1>
            <p className="text-muted-foreground">
              View and manage your saved orders.
            </p>
          </div>
          <Button size="lg" variant="outline" className="ml-auto shrink-0">
            <Link to="/cu/savedorders/new">Create New</Link>
          </Button>
        </div>
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {savedOrders.map((order) => (
              <Card key={order._id}>
                <CardContent className="flex flex-col items-center justify-center gap-4 p-6">
                  <div className="flex items-center gap-2 text-lg font-medium">
                    <ShoppingCartIcon className="h-5 w-5" />
                    <span>{order.name}</span>
                  </div>
                  <div className="flex items-center gap-1 text-muted-foreground">
                    <span>{order.products.length} items</span>
                    <Separator orientation="vertical" className="h-4" />
                    <span>${order.totalPrice.toFixed(2)}</span>
                  </div>

                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Product Name</TableHead>
                        <TableHead className="text-right">Quantity</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {order.products.map((product) => (
                        <TableRow key={product._id}>
                          <TableCell>{product.productName}</TableCell>
                          <TableCell className="text-right">
                            {product.quantity}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <div className="flex gap-2 mt-4">
                    <Button variant="outline">
                      <Link to={`/cu/savedorders/edit/${order._id}`}>Edit</Link>
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => openDeleteDialog(order)}
                    >
                      Delete
                    </Button>
                    <Button onClick={() => addToCartHandler(order)}>
                      Add to Cart
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
            <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Confirm Deletion</DialogTitle>
                  <DialogDescription>
                    Are you sure you want to delete this saved order? This
                    action cannot be undone.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button variant="outline" onClick={closeDeleteDialog}>
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={confirmDelete}>
                    Delete
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    </section>
  );
};

export default SavedOrders;
