import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { auth } from "../firebase";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddPaymentMethodForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements || !currentUser) {
      setProcessing(false);
      return;
    }

    // Create PaymentMethod directly with Stripe
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
      return;
    }

    try {
      // Send only the PaymentMethod ID to our server
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customers/add-payment-method`,
        {
          paymentMethodId: paymentMethod.id,
          uid: currentUser.uid,
        }
      );
      alert("Payment method added successfully");
      navigate("/cu/mycards");
    } catch (err) {
      setError("Failed to add payment method");
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
      {error && <div className="text-red-500">{error}</div>}
      <Button
        type="submit"
        disabled={!stripe || processing}
        className="mt-4 border-2 border-gray-500 text-gray-500 bg-transparent hover:bg-gray-100"
      >
        {processing ? "Processing..." : "Add Payment Method"}
      </Button>
    </form>
  );
};

const AddPaymentMethod = () => {
  return (
    <div>
      <div className="m-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/cu/dashboard">
                <BreadcrumbLink>Dashboard</BreadcrumbLink>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Add Payment Method</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex flex-col gap-6 m-3">
        <div>
          <h1 className="text-2xl font-semibold">Add Payment Method</h1>
          <h1 className="text-sm">
            Your credit card data is directly handled by Stripe Inc and not
            stored on any Spockal servers
          </h1>
        </div>

        <Card>
          <CardContent className="p-6">
            <Elements stripe={stripePromise}>
              <AddPaymentMethodForm />
            </Elements>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AddPaymentMethod;
