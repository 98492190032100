import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { SearchIcon } from "lucide-react";
import { Input } from "@/components/ui/input";
import Fuse from "fuse.js";
import "@fortawesome/fontawesome-free/css/all.css";
import { Link } from "react-router-dom";
import "./stores.css";
import mkplceimg from "./marketplace_wp.webp";
import noStoresFound from "./noStoresFound.webp";
import abdu_five from "./abdu_five.png";
import { Grid } from "react-loader-spinner";
import { Badge } from "@/components/ui/badge";

const Stores = ({ userLatitude, userLongitude, userZipcode }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedStores, setLoadedStores] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [fuseInstance, setFuseInstance] = useState(null);

  useEffect(() => {
    if (userLatitude && userLongitude) {
      fetchStores();
    } else if (userZipcode) {
      fetchStores();
    }
  }, [sendRequest, userLatitude, userLongitude, userZipcode]);

  useEffect(() => {
    const options = { keys: ["name", "description"] };
    const fuse = new Fuse(loadedStores, options);
    setFuseInstance(fuse);
  }, [loadedStores]);

  const handleSearch = (query) => {
    if (!fuseInstance) return;
    if (query) {
      const results = fuseInstance.search(query);
      setSearchResults(results.map((result) => result.item));
    } else {
      setSearchResults(loadedStores);
    }
  };

  const fetchStores = async () => {
    try {
      let url = process.env.REACT_APP_BACKEND_URL + "/stores";
      if (userLatitude && userLongitude) {
        url += `?latitude=${userLatitude}&longitude=${userLongitude}`;
      } else if (userZipcode) {
        url += `?zipcode=${userZipcode}`;
      }
      const responseData = await sendRequest(url);
      setLoadedStores(responseData.stores);
      setSearchResults(responseData.stores);

      setHasFetched(true);
    } catch (err) {
      setHasFetched(true);
    }
  };

  if (!userLatitude && !userLongitude && !userZipcode) {
    return (
      <div>
        <div className="center">
          <h1 className="location-denied">
            Please allow location or enter it manually to see stores.
          </h1>
          <div className="center_grid"></div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* Carousel */}
      <div className="w-full px-4 py-8 md:px-6 md:py-12">
        <div className="bg-white p-4 dark:bg-gray-950">
          <h3 className="font-bold text-xl md:text-2xl">Marketplace</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Various produce, meat, and goods
          </p>
        </div>
        <div className="mb-8 md:mb-12">
          <form className="relative">
            <SearchIcon className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-500 dark:text-gray-400" />
            <Input
              type="search"
              placeholder="Search for stores..."
              className="w-full rounded-xl border border-gray-200 bg-white px-12 py-3 text-gray-900 shadow-sm transition-colors focus:border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-950 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50 dark:focus:border-gray-600 dark:focus:ring-gray-300"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </form>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {searchResults.length === 0 && hasFetched ? (
            <div className="center no-stores-message">
              <h1>No stores match your search.</h1>
            </div>
          ) : (
            searchResults.map((store) => (
              <div
                key={store._id}
                className="relative group overflow-hidden rounded-lg shadow-md hover:shadow-xl transition-transform duration-300 ease-in-out hover:-translate-y-2"
              >
                <Link
                  to={`/${store._id}/products`}
                  className="absolute inset-0 z-10"
                >
                  <span className="sr-only">View Store</span>
                </Link>
                <img
                  src={store.image}
                  alt={store.name}
                  className="object-cover w-full h-48 sm:h-56 md:h-64"
                />
                <div className="bg-white p-4 dark:bg-gray-950">
                  <Badge className="bg-black text-white">
                    {store.distance} Miles
                  </Badge>
                  <h3 className="font-bold text-lg md:text-xl">{store.name}</h3>
                </div>
              </div>
            ))
          )}
        </div>
        {isLoading && (
          <div className="center_grid">
            <Grid
              height="80"
              width="80"
              color="#000000"
              ariaLabel="grid-loading"
              radius="12.5"
              visible={true}
            />
          </div>
        )}
        {!isLoading && hasFetched && loadedStores.length === 0 && (
          <div className="error-container">
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title error-title">
                      Sorry, we couldn't find any stores in your area.
                    </h5>
                    <p className="card-text error-text">
                      Please contact us at support@spockal.com to suggest stores
                      we should add.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <img
                      className="noStores"
                      src={noStoresFound}
                      alt="No stores found"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Stores;
