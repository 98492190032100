import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHttpClient } from "../shared/hooks/http-hook";
import { Grid } from "react-loader-spinner";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import Toastify from "toastify-js";
import { Link } from "react-router-dom";
import { Badge } from "@/components/ui/badge";

const ProductMore = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pid } = useParams();
  const { isLoading, sendRequest } = useHttpClient();
  const [product, setProduct] = useState(location.state || null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    const fetchProductAndStore = async () => {
      if (!product && pid) {
        try {
          // Fetch product details
          const productResponse = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/products/${pid}`
          );
          setProduct(productResponse.product);
          setSelectedImage(productResponse.product.imageUrls?.[0]);

          // Fetch store name using the storeId from the product details
          const storeResponse = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/stores/${productResponse.product.storeId}`
          );
          setStoreName(storeResponse.store.name);
        } catch (err) {
          console.error(err);
        }
      }
    };

    fetchProductAndStore();
  }, [pid, sendRequest, product]);

  const addToCartHandler = () => {
    let cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = cartItems.find((item) => item.id === product.id);

    if (existingItem) {
      existingItem.quantity += quantity;
    } else {
      cartItems.push({ id: product.id, quantity: quantity });
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));
    Toastify({
      text: `Added ${quantity} items to Cart!`,
      duration: 1500,
      newWindow: true,
      close: true,
      gravity: "top", // `top` or `bottom`
      position: "left", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
  };

  if (isLoading) {
    return <div>loading</div>;
  }

  if (!product) {
    return <div>loading</div>;
  }

  return (
    <div>
      <Breadcrumb className="m-5">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">
              <BreadcrumbLink>Marketplace</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to={`/${product?.storeId}/products`}>
              <BreadcrumbLink>{storeName || "Store"}</BreadcrumbLink>{" "}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{product.title}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="grid md:grid-cols-2  items-start max-w-6xl px-4 mx-auto py-6">
        <div className="flex gap-4 items-start">
          <div className="flex flex-col gap-2 ">
            {product.imageUrls?.map((imgUrl, index) => (
              <button
                key={index}
                className="border hover:border-gray-900 rounded-xl overflow-hidden transition-colors dark:hover:border-gray-50"
                onClick={() => setSelectedImage(imgUrl)}
              >
                <img
                  src={imgUrl}
                  alt={`Preview thumbnail ${index + 1}`}
                  className="w-full object-cover h-24" // Set a fixed height for thumbnails
                />
              </button>
            ))}
          </div>
          <img
            src={selectedImage}
            alt={product.title || "Product Title"}
            className="w-full md:w-3/4 lg:w-1/2 object-cover border border-gray-200 rounded-xl overflow-hidden dark:border-gray-800"
          />
        </div>

        <div className="grid gap-4 md:gap-10 items-start md:ml-4">
          <div className="grid gap-4">
            <div className="mt-3">
              {product.salePrice ? (
                <Badge className="bg-blue-500 text-white text-sm	 rounded-md px-4 py-1 hover:bg-green-500 hover:text-white">
                  Limited Time Sale!
                </Badge>
              ) : (
                // Placeholder div with the same styling as the badge to preserve space
                <div style={{ visibility: "hidden" }}>
                  <Badge>Limited Time Sale</Badge>
                </div>
              )}
            </div>
            <h1 className="font-bold text-3xl lg:text-4xl">{product.title}</h1>
            <div>
              <p>{product.description}</p>
            </div>
            <div className="text-4xl font-bold">
              {product.salePrice ? (
                <div className="flex items-center">
                  {/* Sale price */}
                  <span className="text-blue-500 font-bold text-3xl">
                    ${product.salePrice}
                  </span>
                  {/* Original price crossed out */}
                  <span className="text-gray-500 line-through ml-2 text-lg">
                    ${product.price}
                  </span>
                </div>
              ) : (
                // Only original price if no sale price
                <p className="text-gray-700 text-base text-lg">
                  ${product.price}
                </p>
              )}
            </div>
          </div>
          <form className="flex gap-4 items-center">
            <label htmlFor="quantity" className="text-base">
              Quantity
            </label>
            <select
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              className="form-select"
            >
              {[...Array(10).keys()].map((x) => (
                <option key={x + 1} value={x + 1}>
                  {x + 1}
                </option>
              ))}
            </select>
            <Button
              type="button"
              className="btn btn-primary rounded-xl"
              onClick={addToCartHandler}
            >
              Add to Cart
            </Button>
          </form>

          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ProductMore;
