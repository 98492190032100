import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ChevronDownIcon } from "lucide-react";

const OneClick = () => {
  return (
    <div className="w-full py-8 px-4 md:px-6">
      <h1 className="text-2xl font-bold mb-6">One Click Orders</h1>
      <div className="grid gap-6">
        <div className="bg-background rounded-lg border overflow-hidden">
          <div className="flex items-center justify-between px-6 py-4">
            <div className="text-2xl font-bold">Order #1234</div>
            <div className="text-muted-foreground">3 items</div>
            <div className="text-muted-foreground">$149.99</div>
            <Button variant="outline" size="sm">
              Order Again
            </Button>
          </div>
          <div className="ml-2 mr-2">
            <Separator />
          </div>
          <Accordion type="single" collapsible>
            <AccordionItem value="order-1">
              <AccordionTrigger className="px-6 py-4 flex items-center justify-between">
                <div>View Order Details</div>
              </AccordionTrigger>
              <AccordionContent className="px-6 pb-4">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Product</TableHead>
                      <TableHead>Quantity</TableHead>
                      <TableHead className="text-right">Price</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">Product 1</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell className="text-right">$29.99</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Product 2</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell className="text-right">$49.99</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Product 3</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell className="text-right">$70.00</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default OneClick;
