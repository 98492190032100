import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const EditSavedOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [orderName, setOrderName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const [addedProducts, setAddedProducts] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchSavedOrder(currentUser.uid, orderId);
      } else {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, orderId]);

  const fetchSavedOrder = async (uid, orderId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customers/${uid}/myorders/${orderId}`
      );
      setOrderName(response.data.name);
      setAddedProducts(response.data.products);
    } catch (err) {
      console.error("Error fetching saved order:", err);
      setError("Failed to fetch saved order. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuantityChange = (productId, quantity) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === productId
          ? { ...product, quantity: parseInt(quantity) }
          : product
      )
    );
  };

  const handleDeleteProduct = (productId) => {
    setAddedProducts((prev) =>
      prev.filter((product) => product._id !== productId)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) return;

    const totalPrice = addedProducts.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );

    const updatedOrder = {
      name: orderName,
      products: addedProducts.map((product) => ({
        productId: product._id,
        storeId: product.storeId,
        price: product.price,
        quantity: product.quantity,
      })),
      totalPrice,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/customers/${user.uid}/myorders/${orderId}`,
        updatedOrder
      );
      navigate("/cu/savedorders");
    } catch (err) {
      console.error("Error updating saved order:", err);
      setError("Failed to update saved order. Please try again.");
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <section className="w-full py-12">
      <div className="container grid gap-8 px-4 md:px-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/cu/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/cu/savedorders">
                Saved Orders
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Edit Saved Order</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl font-bold tracking-tight">
            Edit {orderName} order
          </h1>
          {error && <p className="text-red-500">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <Label htmlFor="orderName">Order Name</Label>
              <Input
                id="orderName"
                value={orderName}
                onChange={(e) => setOrderName(e.target.value)}
                required
              />
            </div>

            {/* Added Products Table */}
            {addedProducts.length > 0 && (
              <div>
                <h2 className="text-lg font-semibold mb-4">Order Products</h2>
                <div className="mb-8 border rounded-lg">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Product</TableHead>
                        <TableHead className="text-right">Price</TableHead>
                        <TableHead className="text-center">Quantity</TableHead>
                        <TableHead className="text-right">Action</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {addedProducts.map((product) => (
                        <TableRow key={product._id}>
                          <TableCell className="font-medium">
                            {product.productName}
                          </TableCell>
                          <TableCell className="text-right">
                            ${product.price}
                          </TableCell>
                          <TableCell className="text-center">
                            <div className="flex justify-center">
                              <Select
                                value={product.quantity.toString()}
                                onValueChange={(value) =>
                                  handleQuantityChange(
                                    product._id,
                                    parseInt(value)
                                  )
                                }
                              >
                                <SelectTrigger className="w-20">
                                  <SelectValue placeholder="Qty" />
                                </SelectTrigger>
                                <SelectContent>
                                  {[...Array(15)].map((_, index) => (
                                    <SelectItem
                                      key={index + 1}
                                      value={(index + 1).toString()}
                                    >
                                      {index + 1}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="destructive"
                              size="sm"
                              onClick={() => handleDeleteProduct(product._id)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            )}

            <Button type="submit">Update Order</Button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditSavedOrder;
