import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = forwardRef(
  ({ onPaymentResult, onValidityChange }, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFormComplete, setIsFormComplete] = useState(false);

    useImperativeHandle(ref, () => ({
      handlePayment: handleSubmit,
      isValid: () => isFormComplete,
    }));

    useEffect(() => {
      onValidityChange(isFormComplete);
    }, [isFormComplete, onValidityChange]);

    const handleSubmit = async () => {
      if (!stripe || !elements) {
        return { success: false, message: "Stripe not initialized" };
      }

      setIsProcessing(true);

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
        },
        redirect: "if_required",
      });

      setIsProcessing(false);

      if (error) {
        console.error("Payment confirmation error:", error);
        setMessage(error.message);
        return { success: false, message: error.message };
      } else if (paymentIntent) {
        console.log("Payment intent status:", paymentIntent.status);
        switch (paymentIntent.status) {
          case "succeeded":
          case "requires_capture":
            setMessage("Payment authorized successfully");
            return {
              success: true,
              clientSecret: paymentIntent.client_secret,
              paymentIntentId: paymentIntent.id,
            };
          case "processing":
            setMessage("Your payment is processing.");
            return { success: false, message: "Payment is still processing" };
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            return {
              success: false,
              message: "Payment requires another method",
            };
          default:
            console.error(
              "Unexpected payment intent status:",
              paymentIntent.status
            );
            setMessage("An unexpected error occurred.");
            return {
              success: false,
              message: `Unexpected payment status: ${paymentIntent.status}`,
            };
        }
      } else {
        console.error("Neither error nor paymentIntent returned");
        setMessage("An unexpected error occurred.");
        return {
          success: false,
          message: "Neither error nor paymentIntent returned",
        };
      }
    };

    return (
      <form id="payment-form">
        <PaymentElement
          onChange={(event) => {
            setIsFormComplete(event.complete);
            onValidityChange(event.complete);
          }}
          options={{
            layout: "tabs",
          }}
        />
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  }
);

export default React.memo(CheckoutForm);
