import { Button } from "@/components/ui/button";
import {
  BellIcon,
  BookmarkIcon,
  ListIcon,
  MapPinIcon,
  SettingsIcon,
  ShoppingCartIcon,
  UserIcon,
} from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../firebase"; // Adjust the path as necessary
import axios from "axios";
import { Link } from "../../node_modules/react-router-dom/dist/index";
import { CreditCard } from "lucide-react";
import { Headset } from "lucide-react";
import { Route } from "lucide-react";
const Dashboard = () => {
  const [user, setUser] = useState(null);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in, fetch additional info from your backend
        fetchUserInfo(firebaseUser.uid);
      } else {
        // User is signed out
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const fetchUserInfo = async (uid) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/customers/${uid}`
      );
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  return (
    <div className="flex flex-col gap-8 p-4 sm:p-6 md:p-8">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">
          Welcome {user ? user.firstName : ""}
        </h1>
        {/* <div className="flex items-center gap-2">
          <Button variant="outline" size="sm">
            <BellIcon className="h-4 w-4" />
            <span className="sr-only">Notifications</span>
          </Button>
          <Button variant="outline" size="sm">
            <SettingsIcon className="h-4 w-4" />
            <span className="sr-only">Settings</span>
          </Button>
        </div> */}
      </div>
      <section>
        <h2 className="mb-4 text-xl font-bold">Orders</h2>
        <div className="flex gap-4 overflow-x-auto pb-4">
          {[
            // {
            //   title: "1-Click Order",
            //   icon: <ShoppingCartIcon className="h-8 w-8" />,
            //   description:
            //     "Reorder your most common items with a single click.",
            //   buttonText: "Order Now",
            //   link: "/cu/oneclick",
            // },
            {
              title: "Past Orders",
              icon: <ListIcon className="h-8 w-8" />,
              description: "View and track your previous orders.",
              buttonText: "View Orders",
              link: "/cu/pastorders",
            },
            {
              title: "Saved Orders",
              icon: <BookmarkIcon className="h-8 w-8" />,
              description: "Save your favorite orders.",
              buttonText: "View Saved",
              link: "/cu/savedorders",
            },
            {
              title: "Track Orders",
              icon: <Route className="h-8 w-8" />,
              description: "Track your orders.",
              buttonText: "Track",
              link: "/ordertracking",
            },
          ].map((card, index) => (
            <Card
              key={index}
              className="flex-1 min-w-[240px] flex-shrink-0 flex flex-col"
            >
              <CardHeader>
                <CardTitle>{card.title}</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col items-center justify-between flex-grow">
                <div className="flex flex-col items-center gap-2">
                  {card.icon}
                  <p className="text-sm text-muted-foreground text-start">
                    {card.description}
                  </p>
                </div>
                <Link to={card.link} className="mt-auto">
                  <Button size="sm">{card.buttonText}</Button>
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>
      <section>
        <h2 className="mb-4 text-xl font-bold">Your Information</h2>
        <div className="flex gap-4 overflow-x-auto pb-4">
          {[
            {
              title: "My Addresses",
              icon: <MapPinIcon className="h-8 w-8" />,
              description: "Manage your saved addresses.",
              buttonText: "View Addresses",
              link: "/cu/myaddresses",
            },
            {
              title: "My Payment Methods",
              icon: <CreditCard className="h-8 w-8" />,
              description: "Manage your payment methods.",
              buttonText: "View Payment Methods",
              link: "/cu/mycards",
            },
          ].map((card, index) => (
            <Card
              key={index}
              className="flex-1 min-w-[240px] flex-shrink-0 flex flex-col"
            >
              <CardHeader>
                <CardTitle>{card.title}</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col items-center justify-between flex-grow">
                <div className="flex flex-col items-center gap-2">
                  {card.icon}
                  <p className="text-sm text-muted-foreground text-center">
                    {card.description}
                  </p>
                </div>
                <Link to={card.link} className="mt-auto">
                  <Button className="m-1" size="sm">
                    {card.buttonText}
                  </Button>
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      </section>

      <section>
        <h2 className="mb-4 text-xl font-bold">Account Settings</h2>
        <div className="flex gap-4 overflow-x-auto pb-4">
          <Card className="flex-1 min-w-[240px] flex-shrink-0">
            <CardHeader>
              <CardTitle>My Account Info</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center justify-center gap-2">
              <UserIcon className="h-8 w-8" />
              <p className="text-sm text-muted-foreground">
                Update your personal information.
              </p>
              <Link to="/cu/myaccount">
                <Button size="sm">View Profile</Button>
              </Link>
            </CardContent>
          </Card>
        </div>
      </section>
      <section>
        <h2 className="mb-4 text-xl font-bold">Support</h2>
        <div className="flex gap-4 overflow-x-auto pb-4">
          <Card className="flex-1 min-w-[240px] flex-shrink-0">
            <CardHeader>
              <CardTitle>Support</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center justify-center gap-2">
              <Headset className="h-8 w-8" />
              <p className="text-sm text-muted-foreground">Access support</p>
              <Link to="/support">
                <Button size="sm">Access Support</Button>
              </Link>
            </CardContent>
          </Card>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
