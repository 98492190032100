import React, { createContext, useState } from "react";

export const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
  const [canAccessPlaceOrder, setCanAccessPlaceOrder] = useState(false);

  return (
    <CheckoutContext.Provider
      value={{ canAccessPlaceOrder, setCanAccessPlaceOrder }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
