import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { auth } from "../firebase";
import axios from "../../node_modules/axios/index";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";

const MyAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({
    name: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
  });

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/customers/getaddy/${user.uid}`
      );
      setAddresses(response.data.addresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/customers/addaddy`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(newAddress),
        }
      );
      if (response.ok) {
        fetchAddresses();

        setNewAddress({
          name: "",
          street: "",
          city: "",
          state: "",
          zipCode: "",
        });
      }
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  const handleSelectAddress = async (addressId) => {
    try {
      const idToken = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/customers/setprimaryaddy`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ addressId }),
        }
      );
      if (response.ok) {
        fetchAddresses();
      }
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/cu/dashboard">
              <BreadcrumbLink>Dashboard</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>

          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>My Addresses</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="mb-8">
        <h1 className="text-3xl font-bold">My Addresses</h1>
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        {/* Left side: Address cards */}
        <div className="w-full md:w-1/2">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {addresses.map((address) => (
              <div
                key={address._id}
                className={`bg-card p-4 rounded-lg shadow ${
                  address.isChosen ? "border-2 border-black" : ""
                }`}
              >
                <div className="mb-4">
                  <h2 className="text-lg font-medium">{address.name}</h2>
                  <p className="text-muted-foreground">
                    {address.street}, {address.city}, {address.state}{" "}
                    {address.zipCode}
                  </p>
                </div>
                <Button
                  variant="outline"
                  className="w-full"
                  onClick={() => handleSelectAddress(address._id)}
                >
                  {address.isChosen ? "Selected" : "Select"}
                </Button>
              </div>
            ))}
          </div>
        </div>

        {/* Right side: Add New Address form */}
        <div className="w-full md:w-1/2">
          <h2 className="text-2xl font-bold mb-4">Add New Address</h2>
          <form className="space-y-4" onSubmit={handleAddAddress}>
            <div>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={newAddress.name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, name: e.target.value })
                }
                placeholder="Home, Work, etc."
              />
            </div>
            <div>
              <Label htmlFor="street">Street Address</Label>
              <Input
                id="street"
                value={newAddress.street}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, street: e.target.value })
                }
                placeholder="123 Main St"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="city">City</Label>
                <Input
                  id="city"
                  value={newAddress.city}
                  onChange={(e) =>
                    setNewAddress({ ...newAddress, city: e.target.value })
                  }
                  placeholder="Anytown"
                />
              </div>
              <div>
                <Label htmlFor="state">State</Label>
                <Input
                  id="state"
                  value={newAddress.state}
                  onChange={(e) =>
                    setNewAddress({ ...newAddress, state: e.target.value })
                  }
                  placeholder="CA"
                />
              </div>
            </div>
            <div>
              <Label htmlFor="zipCode">Zip Code</Label>
              <Input
                id="zipCode"
                value={newAddress.zipCode}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, zipCode: e.target.value })
                }
                placeholder="12345"
              />
            </div>
            <Button type="submit" className="w-full">
              Save Address
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyAddress;
