import React from "react";

const LoadingSpinner = (props) => {
  return (
    <div className={`${props.asOverlay && "spinner-border"}`}>
      <span class="sr-only"></span>
    </div>
  );
};

export default LoadingSpinner;
