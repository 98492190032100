// location.js

const getUserLocation = (successCallback, errorCallback) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        successCallback(position.coords);
      },
      (error) => {
        errorCallback(error);
      }
    );
  } else {
    errorCallback({ message: "Geolocation is not supported by this browser." });
  }
};

export default getUserLocation;
