import { useState } from "react";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import SPKL_LOGO from "../spkl_logo.svg";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app, auth } from "../firebase";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  //   const auth = getAuth(app);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/cu/dashboard"); // Redirect to dashboard or home page after successful login
      console.log("Welcome Home");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center h-screen bg-background">
        <div className="w-full max-w-md space-y-6 mt-8 md:mt-12">
          <Link to="/">
            <div className="flex flex-row items-center justify-center space-x-2">
              <img src={SPKL_LOGO} className="h-8 w-8" alt="Spockal Logo" />
              <div className="text-2xl font-bold">Spockal</div>
            </div>
          </Link>

          <Card>
            <CardContent className="space-y-4 mt-4">
              <h1 className="text-3xl font-bold">Welcome Back..</h1>
              <form onSubmit={handleLogin} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <Label htmlFor="password">Password</Label>
                    {/* <Link
                      to="/forgot-password"
                      className="text-sm text-muted-foreground hover:underline"
                    >
                      Forgot Password?
                    </Link> */}
                  </div>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                  />
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <Button type="submit" className="w-full">
                  Sign In
                </Button>
              </form>
            </CardContent>
          </Card>

          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-black">
              Don't have an Account?
            </span>
          </div>
          <div className="w-full">
            <Link to="/auth/signup">
              <button className="bg-slate-200 text-black py-2 px-4 rounded-full font-medium hover:bg-custom-gray-hvr py-3 px-4 rounded-lg w-full flex items-center justify-center">
                Create an account
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
