import React, { useEffect, useState } from "react";
import UserProductList from "../components/userProductList";
import { useParams, useNavigate } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import {
  faSearch,
  faArrowLeft,
  faShareSquare,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import "./userStoreProducts.css";
import Fuse from "fuse.js";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Facebook, Instagram, Share2, Twitter } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";

const UserStoreProducts = () => {
  const [loadedProducts, setLoadedProducts] = useState([]);
  const { isLoading, sendRequest } = useHttpClient();
  const storeId = useParams().storeId;
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [displayProducts, setDisplayProducts] = useState([]); // Unified array for display
  const [storeName, setStoreName] = useState("");
  const [fuseInstance, setFuseInstance] = useState(null);
  const [socials, setSocials] = useState({
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
  });
  const [tags, setTags] = useState([]); // Stores all tags sorted by frequency
  const [selectedTag, setSelectedTag] = useState(""); // Stores the currently selected tag

  // Modify the useEffect hook that sets displayProducts to consider the selectedTag
  useEffect(() => {
    if (selectedTag) {
      const filteredProducts = loadedProducts.filter((product) =>
        product.tags.includes(selectedTag)
      );
      setDisplayProducts(filteredProducts);
    } else {
      setDisplayProducts(loadedProducts); // No tag selected, display all products
    }
  }, [selectedTag, loadedProducts]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/products/store/${storeId}`
        );
        const userViewProducts = responseData.products.filter(
          (product) => product.status
        );
        const storeNamesResponse = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/stores/names`,
          "POST",
          JSON.stringify({ storeIds: [storeId] }),
          { "Content-Type": "application/json" }
        );

        if (
          storeNamesResponse.storeNames &&
          storeNamesResponse.storeNames[storeId]
        ) {
          setStoreName(storeNamesResponse.storeNames[storeId]);
        }

        const modifiedProducts = userViewProducts.map((product) => ({
          ...product,
          imageUrl: product.imageUrls[0] || "default-image-url",
          thumbNails: product.imageUrls,
        }));

        setLoadedProducts(modifiedProducts);
        setDisplayProducts(modifiedProducts); // Initially display all products

        // const socialsResponse = await sendRequest(
        //   `${process.env.REACT_APP_BACKEND_URL}/socials/${storeId}`
        // );
        // if (socialsResponse && socialsResponse.socials) {
        //   setSocials(socialsResponse.socials);
        // }

        const options = { keys: ["name", "description"] };
        const fuse = new Fuse(modifiedProducts, options);
        setFuseInstance(fuse);
        const tagFrequency = {};
        userViewProducts.forEach((product) => {
          product.tags.forEach((tag) => {
            if (tagFrequency[tag]) {
              tagFrequency[tag] += 1;
            } else {
              tagFrequency[tag] = 1;
            }
          });
        });
        console.log(userViewProducts);

        // Sort tags by frequency
        const sortedTags = Object.entries(tagFrequency)
          .sort((a, b) => b[1] - a[1])
          .map((entry) => entry[0]);

        setTags(sortedTags); // Assume setTags is a useState hook for storing tags
      } catch (err) {
        // Handle the error appropriately
      }
    };
    fetchProducts();
  }, [sendRequest, storeId]);

  useEffect(() => {
    if (searchQuery && fuseInstance) {
      const results = fuseInstance
        .search(searchQuery)
        .map((result) => result.item);
      setDisplayProducts(results);
    } else {
      setDisplayProducts(loadedProducts); // No search query, display all products
    }
  }, [searchQuery, loadedProducts, fuseInstance]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const goBack = () => navigate("/");
  const [toast, setToast] = useState(false); // State to control the visibility of the toast

  // Function to show toast and hide after a few seconds
  const showToast = () => {
    setToast(true);
    setTimeout(() => setToast(false), 3000); // Hide toast after 3 seconds
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: storeName,
          url: window.location.href,
        });
      } catch (error) {
        console.log("Error sharing:", error);
      }
    } else {
      // Use Clipboard API to copy the URL
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          // Show toast notification
          Toastify({ text: "Link Copied", duration: 3000 }).showToast();
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  // Inside your component's return statement
  return (
    <React.Fragment>
      <Breadcrumb className="m-5">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/">
              <BreadcrumbLink>Marketplace</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink>{storeName}</BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="TAcenter">
        {isLoading && <LoadingSpinner />}
        <div className="text-center flex justify-center items-center flex-wrap">
          <h1 className="text-4xl">{storeName || "Products"}</h1>
          <div className="social-links flex items-center text-3xl">
            {socials.facebookUrl && (
              <a
                href={socials.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 "
              >
                <Facebook className="text-blue-500" />
              </a>
            )}
            {socials.instagramUrl && (
              <a
                href={socials.instagramUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2.5"
              >
                <Instagram className="text-pink-500" />
              </a>
            )}
            {socials.twitterUrl && (
              <a
                href={socials.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2.5"
              >
                <Twitter className="text-blue-200" />
              </a>
            )}
            <a href="#" onClick={handleShare} className="mx-2.5">
              <Share2 />
            </a>
          </div>
        </div>
        {/* <button onClick={goBack} className="btn btn-link flex items-center">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Marketplace
        </button> */}
        <div className="search-bar-container flex m-4">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            className="flex-grow"
          />
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
        </div>
        <div className="flex justify-center items-center my-4">
          <div className="flex flex-wrap justify-center gap-2 max-w-4xl">
            <Button
              onClick={() => setSelectedTag("")}
              className={`px-4 py-2 rounded-full transition-colors hover:bg-black hover:text-white
            ${
              selectedTag === ""
                ? "bg-black text-white"
                : "bg-transparent border border-black text-black"
            }`}
            >
              All
            </Button>

            {tags.map((tag) => (
              <Button
                key={tag}
                onClick={() => setSelectedTag(tag)}
                className={`px-4 py-2 rounded-full transition-colors hover:bg-black hover:text-white
              ${
                selectedTag === tag
                  ? "bg-black text-white"
                  : "bg-transparent border border-black text-black"
              }`}
              >
                {tag}
              </Button>
            ))}
          </div>
        </div>

        {!isLoading && displayProducts.length > 0 && (
          <UserProductList items={displayProducts} />
        )}

        {isLoading && !loadedProducts.length && (
          <div>
            <LoadingSpinner asOverlay />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UserStoreProducts;
