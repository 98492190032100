import React from "react";
import UserProductItem from "./userProductItem";
import "./productList.css";

const UserProductList = (props) => {
  // if (props.items.length === 0) {
  //   return (
  //     <div className="card">
  //       <div className="card-header">No Products</div>
  //       <div className="card-body">
  //         <h5 className="card-title">No Products Found</h5>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 m-4">
      {props.items.map((product) => (
        <div key={product.id} className="h-full">
          <UserProductItem
            id={product.id}
            imageURL={product.imageUrl}
            thumbNail={product.thumbNails}
            title={product.title}
            price={product.price}
            salePrice={product.salePrice}
            description={product.description}
          />
        </div>
      ))}
    </div>
  );
};

export default UserProductList;
