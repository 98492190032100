export const getLatLongFromZipcode = async (zipcode) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/geocode/getLocation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ zipcode }),
      }
    );
    const data = await response.json();
    return { latitude: data.latitude, longitude: data.longitude };
  } catch (error) {
    console.error("Error fetching lat/long:", error);
  }
};
