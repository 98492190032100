import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { ShoppingCartIcon } from "lucide-react";
import SPKL_LOGO from "../../spkl_logo.svg";
import { useContext, useEffect, useState } from "react";
import { getLatLongFromZipcode } from "../../services/latlongFZip";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { app } from "../../firebase";
import { useNavigate } from "react-router-dom";

const ClientNav = ({ zipcode, setZipcode, setUserCoordinates }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth(app);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);

  const [tempZipcode, setTempZipcode] = useState(zipcode); // Temporary state for modal input
  const handleZipcodeChange = (event) => {
    event.preventDefault(); // Prevent default form submission
    setTempZipcode(event.target.value); // Update temporary zipcode
    // setShowModal(false);
  };
  const handleSaveChanges = async () => {
    const coords = await getLatLongFromZipcode(tempZipcode);
    if (coords) {
      setUserCoordinates(coords.latitude, coords.longitude);
    }
    setZipcode(tempZipcode);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, [auth]);
  const handleAuthAction = async () => {
    if (isLoggedIn) {
      try {
        await signOut(auth);
        navigate("/"); // Redirect to home page after logout
      } catch (error) {
        console.error("Logout error:", error);
      }
    } else {
      navigate("/auth/login");
    }
  };
  return (
    <header className="w-full bg-white  dark:bg-gray-950">
      <div className="flex items-center justify-between h-20 px-4 md:px-6">
        <div className="flex items-center  flex-grow">
          <Link to="/" className="flex items-center gap-3" prefetch={false}>
            <img className="w-10 h-10" src={SPKL_LOGO} alt="Spockal Logo" />
            <span className="font-bold text-2xl hidden sm:block">Spockal</span>
          </Link>
          {/* Dialog for Zip Code input next to the logo */}
          <Dialog className>
            <DialogTrigger asChild>
              <Input
                type="text"
                placeholder="Enter zip code"
                className="w-20 sm:w-20 md:w-20 rounded-xl ml-1 mr-5 border"
                value={`@${zipcode}` || ""}
                readOnly
              />
            </DialogTrigger>
            <DialogContent className="w-80 p-4 space-y-4 bg-white dark:bg-gray-950 rounded-lg shadow-lg">
              <div className="space-y-2">
                <h4 className="font-medium">Save Zip Code</h4>
                <p className="text-gray-500 dark:text-gray-400">
                  Enter your zip code to save it for future use.
                </p>
              </div>
              <div className="grid gap-2">
                <Input
                  type="text"
                  placeholder="Enter zip code"
                  className="w-32 sm:w-40 md:w-48 rounded-xl"
                  value={tempZipcode || ""}
                  onChange={handleZipcodeChange}
                  maxLength={5}
                />
                <div className="flex justify-end gap-2">
                  <DialogClose asChild>
                    <Button type="button" variant="secondary">
                      Close
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="button" onClick={handleSaveChanges}>
                      Save
                    </Button>
                  </DialogClose>
                </div>
              </div>
            </DialogContent>
          </Dialog>
          <div className="hidden md:flex items-center gap-6">
            {isLoggedIn && (
              <Link
                to="/cu/dashboard"
                className="inline-flex items-center gap-3 text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
                prefetch={false}
              >
                Dashboard
              </Link>
            )}
            <Link
              to="/"
              className="inline-flex items-center gap-3 text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
              prefetch={false}
            >
              Marketplace
            </Link>
            {!isLoggedIn && (
              <Link
                to="/ordertracking"
                className="inline-flex items-center gap-3 text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
                prefetch={false}
              >
                Order Tracking
              </Link>
            )}
            {!isLoggedIn && (
              <Link
                to="/support"
                className="inline-flex items-center gap-3 text-lg font-medium transition-colors hover:text-gray-900 focus:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50 dark:focus:text-gray-50"
                prefetch={false}
              >
                Support
              </Link>
            )}
          </div>
        </div>
        <div className="flex gap-6">
          <Button
            onClick={handleAuthAction}
            className="inline-flex items-center gap-3 text-lg font-medium transition-colors duration-200 ease-in-out cursor-pointer select-none touch-manipulation hover:bg-gray-700 focus:outline-none focus dark:bg-gray-800 dark:hover:bg-gray-600 "
          >
            {isLoggedIn ? "Logout" : "Login"}
          </Button>
          <Link to="/cart">
            <Button
              variant="outline"
              size="icon"
              className="relative"
              aria-label="Shopping Cart"
            >
              <ShoppingCartIcon className="w-8 h-8" />
            </Button>
          </Link>
        </div>
      </div>
      <hr />
    </header>
  );
};

export default ClientNav;
