// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoV9IUcRxF6EV_cPFofU0KBRI76kqjGp0",
  authDomain: "spkl-auth.firebaseapp.com",
  projectId: "spkl-auth",
  storageBucket: "spkl-auth.appspot.com",
  messagingSenderId: "946963008133",
  appId: "1:946963008133:web:34d278cfe3dc45c92d77ec",
  measurementId: "G-X852B7V89Q",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence set to local");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });
const analytics = getAnalytics(app);

export { app, auth };
