import React, { useEffect, useState } from "react";
import axios from "axios";
import { auth } from "../firebase"; // Ensure you import your firebase setup
import { Link } from "react-router-dom";
import { CreditCardIcon, StarIcon, TrashIcon, PlusIcon } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const MyCards = () => {
  const [cards, setCards] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentUser) {
      console.log("Current user UID:", currentUser.uid);
      fetchCards();
    }
  }, [currentUser]);

  const fetchCards = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customers/paymentmethods`,
        { uid: currentUser.uid }
      );
      setCards(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      }
    }
  };

  const setPrimaryCard = async (paymentMethodId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customers/set-primary-payment-method`,
        {
          uid: currentUser.uid,
          paymentMethodId,
        }
      );
      fetchCards(); // Refresh the cards list
    } catch (error) {
      console.error("Error setting primary card:", error);
    }
  };

  const deleteCard = async (paymentMethodId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/customers/payment-methods/${paymentMethodId}`,
        { data: { uid: currentUser.uid } }
      );
      fetchCards(); // Refresh the cards list
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  return (
    <div>
      <div className="m-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/cu/dashboard">
                <BreadcrumbLink>Dashboard</BreadcrumbLink>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Saved Cards</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex flex-col gap-6 m-3">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Saved Cards</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {cards.map((card) => (
            <Card
              key={card.stripePaymentMethodId}
              className={`${card.isPrimary ? "border-primary" : ""} ${
                selectedCard === card.stripePaymentMethodId
                  ? "border-black border-2"
                  : ""
              }`}
              onClick={() => setSelectedCard(card.stripePaymentMethodId)}
            >
              <CardContent className="flex flex-col gap-4 p-5">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <CreditCardIcon className="h-6 w-6 text-primary" />
                    <span className="text-lg font-medium">{card.brand}</span>
                  </div>
                  <span className="text-muted-foreground">
                    **** **** **** {card.last4 || "****"}
                  </span>
                </div>

                <div className="text-muted-foreground">
                  Expires {card.expMonth}/{card.expYear}
                </div>

                <div className="flex justify-between">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setPrimaryCard(card.stripePaymentMethodId);
                    }}
                    disabled={card.isPrimary}
                  >
                    <StarIcon
                      className={`h-4 w-4 ${
                        card.isPrimary ? "text-primary" : ""
                      }`}
                    />
                    <span className="sr-only">Set as Primary</span>
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCard(card.stripePaymentMethodId);
                    }}
                  >
                    <TrashIcon className="h-4 w-4" />
                    <span className="sr-only">Delete</span>
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}

          <Link
            to="/cu/addpaymentmethod"
            className="bg-card rounded-lg border p-6 flex flex-col justify-center items-center text-primary hover:text-primary-foreground transition-colors"
            prefetch={false}
          >
            <PlusIcon className="w-8 h-8 mb-2" />
            <span className="text-lg font-medium">Add Card</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyCards;
