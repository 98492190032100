import React from "react";
import "./support.css";
import merchants from "./merchants_spkl.svg";
import customers from "./customers_spkl.svg";
import join from "./joinus_spkl.svg";
import Button from "../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  KeyRound,
  FolderKanban,
  Link2,
  ShoppingBasket,
  TruckIcon,
  HeartHandshake,
} from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
const Support = () => {
  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };
  return (
    <div>
      <div className="m-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/cu/dashboard">
                <BreadcrumbLink>Dashboard</BreadcrumbLink>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Support</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">Support</h1>
        </div>
      </div>

      <div className="w-full max-w-5xl mx-auto py-12 md:py-20 lg:py-28 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 m-3">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Merchants</h2>
            <p className="text-gray-500 dark:text-gray-400">
              Get the support you need to set up your account, manage orders,
              and grow your buisness.
            </p>
            <div className="grid gap-3">
              <Link
                to="/auth/login"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <KeyRound className="h-5 w-5" />
                Login
              </Link>
              <Link
                href="stores.spockal.com"
                target="_blank"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <FolderKanban className="h-5 w-5" />
                Manage orders
              </Link>
              <Link
                href="mailto:support@spockal.com"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <Link2 className="h-5 w-5" />
                Email Support
              </Link>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Customers</h2>
            <p className="text-gray-500 dark:text-gray-400">
              Place orders, track shipments, and email support.
            </p>
            <div className="grid gap-3">
              <Link
                to="/cart"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <ShoppingBasket className="h-5 w-5" />
                Place an order
              </Link>
              <Link
                to="/ordertracking"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <TruckIcon className="h-5 w-5" />
                Track your shipment
              </Link>
              <Link
                href="mailto:support@spockal.com"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <Link2 className="h-5 w-5" />
                Email Support
              </Link>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold">Become a Merchant</h2>
            <p className="text-gray-500 dark:text-gray-400">
              Explore the platform and grow your buisness with the community.
            </p>
            <div className="grid gap-3">
              <Link
                to="/auth/signup"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <HeartHandshake className="h-5 w-5" />
                Join today
              </Link>
              <Link
                href="mailto:support@spockal.com"
                className="flex items-center gap-2 text-blue-600 hover:underline"
                prefetch={false}
              >
                <Link2 className="h-5 w-5" />
                Email Support
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
