import "./termsAConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-wrapper">
      <h1 className="terms">Terms and Conditions:</h1>
      <h5 className="terms-body">
        1. Introduction Welcome to Spockal. These Terms and Conditions govern
        your use of the Spockal website and its services. By accessing Spockal
        and placing orders, you agree to comply with and be bound by these
        terms. 2. Order Placement and Processing a. Orders placed on Spockal
        must be for lawful purposes and in accordance with these Terms. b. We
        reserve the right to refuse service, terminate accounts, or cancel
        orders at our sole discretion. 3. User Responsibilities a. Users must
        provide accurate and current information for all purchases. 4. Order
        Pickup a. Customers must pick up orders within 30 minutes of the
        scheduled pickup time. b. Orders not picked up within this period may be
        canceled, and the account may be subject to suspension for repeated
        offenses. 5. Cancellation, Returns, and Refunds a. Orders not picked up
        within the 1-hour window will be considered canceled. 6. Account
        Suspension and Termination a. We may suspend or terminate your account
        and access to the Services if you fail to comply with these Terms. b.
        Suspension or termination will result in the forfeiture and
        relinquishment of all content in your account. 8. Liability Limitation
        a. Spockal is not liable for any indirect, incidental, punitive, or
        consequential damages resulting from the use of our services or
        purchasing products on our site. 10. Modifications to Terms a. Spockal
        reserves the right to modify these terms at any time, so please review
        them frequently. 12. Contact Information Questions about the Terms and
        Conditions should be sent to us at legal@spockal.com.
      </h5>
    </div>
  );
};

export default TermsAndConditions;
