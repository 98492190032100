import { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/";
const NewSavedOrders = ({ userLatitude, userLongitude, userZipcode }) => {
  const [stores, setStores] = useState([]);
  const [products, setProducts] = useState({});
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orderNickname, setOrderNickname] = useState("");
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  const handleSaveOrder = async () => {
    if (!uid) {
      setError("You must be logged in to save an order");
      return;
    }

    if (!orderNickname) {
      setError("Please provide a nickname for the order");
      return;
    }

    if (addedProducts.length === 0) {
      setError("Please add products to the order");
      return;
    }

    const totalPrice = addedProducts.reduce(
      (sum, product) => sum + product.price * product.quantity,
      0
    );

    const orderData = {
      name: orderNickname,
      products: addedProducts.map((product) => ({
        productId: product._id,
        storeId: selectedStore,
        productName: product.title,
        price: product.price,
        quantity: product.quantity,
      })),
      totalPrice,
    };
    console.log(orderData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/customers/${uid}/save-order`,
        orderData
      );
      console.log("Order saved successfully:", response.data);
      // Clear the form or redirect the user as needed
      setOrderNickname("");
      setAddedProducts([]);
      setSelectedStore(null);
      navigate("/cu/savedorders");
      // You might want to show a success message to the user
    } catch (err) {
      console.error("Error saving order:", err);
      setError("Failed to save order. Please try again.");
    }
  };
  useEffect(() => {
    const fetchStores = async () => {
      if (!userLatitude || !userLongitude) return; // Don't fetch if coordinates are not available

      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/stores`,
          {
            params: {
              latitude: userLatitude,
              longitude: userLongitude,
              zipcode: userZipcode,
            },
          }
        );
        setStores(response.data.stores);
      } catch (err) {
        console.error("Error fetching stores:", err);
        setError("Failed to fetch stores. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStores();
  }, [userLatitude, userLongitude, userZipcode]);

  const fetchStoreProducts = async (storeId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/products/store/${storeId}`
      );
      setProducts((prevProducts) => ({
        ...prevProducts,
        [storeId]: response.data.products,
      }));
    } catch (err) {
      console.error("Error fetching store products:", err);
      setError("Failed to fetch products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStoreSelect = (storeId) => {
    setSelectedStore(storeId);
    setSelectedProducts([]);
    if (!products[storeId]) {
      fetchStoreProducts(storeId);
    }
  };

  const handleProductSelect = (productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId]
    );
  };

  const handleAddSelectedProducts = () => {
    const selectedProductDetails = products[selectedStore]
      .filter((product) => selectedProducts.includes(product._id))
      .map((product) => ({
        ...product,
        quantity: product.quantity || 1, // Use selected quantity or default to 1
      }));
    setAddedProducts((prev) => [...prev, ...selectedProductDetails]);
    setSelectedProducts([]); // Clear selected products
  };

  const handleQuantityChange = (productId, quantity) => {
    setProducts((prevProducts) => ({
      ...prevProducts,
      [selectedStore]: prevProducts[selectedStore].map((product) =>
        product._id === productId
          ? { ...product, quantity: parseInt(quantity) }
          : product
      ),
    }));
  };

  const handleDeleteProduct = (productId) => {
    setAddedProducts((prev) =>
      prev.filter((product) => product._id !== productId)
    );
  };

  return (
    <div className="container mx-auto py-8 px-4 md:px-6">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/cu/dashboard">
              <BreadcrumbLink>Dashboard</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>

          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Saved Orders</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Add Saved Order</h1>
          <Button variant="outline" onClick={handleSaveOrder}>
            Save Order
          </Button>
        </div>
        {userZipcode && (
          <p className="mb-4">Showing stores for zipcode: {userZipcode}</p>
        )}
        <div className="mb-6">
          <Label htmlFor="nickname" className="block mb-2">
            Nickname
          </Label>
          <Input
            id="nickname"
            placeholder="Enter a nickname"
            value={orderNickname}
            onChange={(e) => setOrderNickname(e.target.value)}
          />{" "}
        </div>

        {/* Added Products Table */}
        {addedProducts.length > 0 && (
          <div className="mb-8 border rounded-lg">
            <h2 className="text-lg font-semibold m-4">Selected Products</h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Product</TableHead>
                  <TableHead className="text-right">Price</TableHead>
                  <TableHead className="text-right">Quantity</TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {addedProducts.map((product) => (
                  <TableRow key={product._id}>
                    <TableCell className="font-medium">
                      {product.title}
                    </TableCell>
                    <TableCell className="text-right">
                      ${product.price}
                    </TableCell>
                    <TableCell className="text-right">
                      {product.quantity}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => handleDeleteProduct(product._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}

        {isLoading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          {stores.map((store) => (
            <Button
              key={store._id}
              variant={selectedStore === store._id ? "primary" : "outline"}
              onClick={() => handleStoreSelect(store._id)}
              className="w-full"
            >
              {store.name}
            </Button>
          ))}
        </div>
        {selectedStore !== null && products[selectedStore] && (
          <div className="border rounded-lg overflow-hidden">
            <div className="bg-muted/40 px-4 py-3 flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {stores.find((store) => store._id === selectedStore)?.name}{" "}
                Products
              </h2>
              <Button size="sm" onClick={handleAddSelectedProducts}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Add Selected
              </Button>
            </div>
            <div className="relative w-full overflow-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[32px]">
                      <Checkbox
                        checked={
                          selectedProducts.length ===
                          products[selectedStore].length
                        }
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setSelectedProducts(
                              products[selectedStore].map(
                                (product) => product._id
                              )
                            );
                          } else {
                            setSelectedProducts([]);
                          }
                        }}
                      />
                    </TableHead>
                    <TableHead>Product</TableHead>
                    <TableHead className="text-right">Price</TableHead>
                    <TableHead className="text-right">Quantity</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {products[selectedStore].map((product) => (
                    <TableRow key={product._id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedProducts.includes(product._id)}
                          onCheckedChange={() =>
                            handleProductSelect(product._id)
                          }
                        />
                      </TableCell>
                      <TableCell className="font-medium">
                        {product.title}
                      </TableCell>
                      <TableCell className="text-right">
                        ${product.price}
                      </TableCell>
                      <TableCell>
                        <Select
                          value={
                            product.quantity ? product.quantity.toString() : "1"
                          }
                          onValueChange={(value) =>
                            handleQuantityChange(product._id, parseInt(value))
                          }
                        >
                          <SelectTrigger className="w-20">
                            <SelectValue placeholder="Qty" />
                          </SelectTrigger>
                          <SelectContent>
                            {[...Array(15)].map((_, index) => (
                              <SelectItem
                                key={index + 1}
                                value={(index + 1).toString()}
                              >
                                {index + 1}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewSavedOrders;
