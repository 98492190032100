import React, { useState, useEffect } from "react";
import "./orderStatus.css";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";

const OrderStatus = () => {
  const [email, setEmail] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [orderStatusMessage, setOrderStatusMessage] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get("email");
    const trackingNumberParam = params.get("trackingNumber");

    if (emailParam) setEmail(emailParam);
    if (trackingNumberParam) setTrackingNumber(trackingNumberParam);
  }, []);

  const fetchOrderStatus = async (e) => {
    e.preventDefault();
    setError("");
    setOrderStatusMessage("");
    setAlertClass("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/orders/status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, trackingNumber }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch order status");
      }

      switch (data.status) {
        case "received":
          setOrderStatusMessage(
            "We've got your order, however the store hasn't accepted it yet."
          );
          break;
        case "accepted":
          setOrderStatusMessage("The store is working on your order.");
          setAlertClass("alert-warning");
          break;
        case "ready":
          setOrderStatusMessage("Your order is ready for pickup.");
          setAlertClass("alert-success");
          break;
        default:
          setOrderStatusMessage(
            "Your email or tracking number may be incorrect."
          );
          setAlertClass("alert-danger");
      }
    } catch (err) {
      setError("Sorry, your order tracking number or email is incorrect");
      setAlertClass("alert-danger");
    }
  };

  return (
    <div className="w-full max-w-md mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="space-y-6">
        <div className="text-center">
          <h1 className="text-2xl font-bold md:text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
            Track Your Order
          </h1>
          <p className="mt-3 text-base text-gray-500 dark:text-gray-400">
            Enter your email address and order number to check the status of
            your delivery.
          </p>
        </div>
        <form className="space-y-4" onSubmit={fetchOrderStatus}>
          <div>
            <Label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Email Address
            </Label>
            <div className="mt-1">
              <Input
                id="email"
                type="email"
                placeholder="you@example.com"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Label
              htmlFor="tracking-number"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Tracking Number
            </Label>
            <div className="mt-1">
              <Input
                id="tracking-number"
                type="text"
                placeholder="TRK-213 ..."
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Track Order
            </button>
          </div>
        </form>
        {orderStatusMessage && (
          <div className={`alert ${alertClass} mt-3`}>{orderStatusMessage}</div>
        )}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
    </div>
  );
};

export default OrderStatus;
