import { useNavigate } from "react-router-dom";
import "./order-success.css";
import { CircleCheckIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "../../../node_modules/react-router-dom/dist/index";

const OrderSuccess = ({ trackingNumber }) => {
  const navigate = useNavigate();

  const returnToMainPage = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-6">
              <CircleCheckIcon className="size-12 text-green-500" />
              <div className="space-y-2 text-center">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Thank you for your order!
                </h1>
                <p className="max-w-[600px] text-muted-foreground md:text-xl">
                  We're processing your order now. Check your email for a
                  tracking number.
                </p>
              </div>
              <Button>
                <Link to="/">Return to Home</Link>
              </Button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default OrderSuccess;
